import React, { useState } from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'

const Container = styled.div`
  position: relative;

  input {
    height: 32px;
    padding: 0;
    padding-left: 18px;
    padding-right: 40px;
    margin-bottom: 24px;
    border: none;
    border-radius: 17px;
    background: white;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.06);
  }

  svg {
    position: absolute;
    top: 6px;
    right: 12px;
    width: 16px;
  }
`

export default function SearchQuery(props) {
  const { initialValue, updateValue } = props

  const [value, setValue] = useState(initialValue)

  return (
    <Container>
      <input
        type="text"
        placeholder="Search"
        value={value}
        onChange={(event) => setValue(event.target.value)}
        onBlur={() => updateValue(value)}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.target.blur()
          }
        }}
      />
      <button className="a" onClick={() => updateValue(value)}>
        <SVG src="/images/icons/search.svg" />
      </button>
    </Container>
  )
}
